import { appsNavTree, branchManagerNavTree, salesAdvisorNavTree, serviceAdvisorNavTree } from 'configs/NavigationConfig';
import { useState } from 'react';
import { createContext } from 'react';

export const NavigationContext = createContext(() => {
	const userType = localStorage.getItem('user_type');

	switch (userType) {
		case 'superadmin':
			return { navigationConfig: appsNavTree };
			break;

		case 'branchmanager':
			return { navigationConfig: branchManagerNavTree };
			break;

		case 'salesadvisor':
			return salesAdvisorNavTree;
			break;

		case 'serviceadvisor':
			return serviceAdvisorNavTree;
			break;

		// default:
		// 	return appsNavTree;
	}
});

export const NavigationProvider = ({ children }) => {
	const [navigationConfig] = useState(() => {
		const userType = localStorage.getItem('user_type');

		switch (userType) {
			case 'superadmin':
				return appsNavTree;
				break;

			case 'branchmanager':
				return branchManagerNavTree;
				break;

			case 'salesadvisor':
				return salesAdvisorNavTree;
				break;

			case 'serviceadvisor':
				return serviceAdvisorNavTree;
				break;

			// default:
			// 	return appsNavTree;
		}
	}, []);

	const [userType] = useState(() => {
		return localStorage.getItem('user_type');
	});

	return <NavigationContext.Provider value={{ navigationConfig, userType }}>{children}</NavigationContext.Provider>;
};
